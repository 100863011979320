// extracted by mini-css-extract-plugin
export var button = "Button-module--button--wX7-j";
export var primary = "Button-module--primary--LhfXg";
export var secondary = "Button-module--secondary--54jUC";
export var alternate = "Button-module--alternate--qCCJX";
export var small = "Button-module--small--wDOee";
export var smallest = "Button-module--smallest---hXOR";
export var large = "Button-module--large--5QjBW";
export var fullWidth = "Button-module--fullWidth--c3oAE";
export var disabled = "Button-module--disabled--nJh8f";
export var tertiary = "Button-module--tertiary--xQPfO";
export var flat = "Button-module--flat--LCR50";
export var link = "Button-module--link--x3ZBI";