// extracted by mini-css-extract-plugin
export var root = "Footer-module--root--Q8fNK";
export var content = "Footer-module--content--Kusw8";
export var contentTop = "Footer-module--contentTop--cpwZp";
export var newsLetter = "Footer-module--newsLetter--kVAJL";
export var newsLetterContent = "Footer-module--newsLetterContent--4QrW6";
export var linkList = "Footer-module--linkList--b5W0+";
export var link = "Footer-module--link--W4s8p";
export var linkTitle = "Footer-module--linkTitle--+hOKC";
export var promoMessage = "Footer-module--promoMessage--tgnsD";
export var newsLetterForm = "Footer-module--newsLetterForm--EXh3Y";
export var socialContainer = "Footer-module--socialContainer--QzMkw";
export var socialIconContainer = "Footer-module--socialIconContainer--rDYK+";
export var contentBottomContainer = "Footer-module--contentBottomContainer--lb+DN";
export var contentBottom = "Footer-module--contentBottom--1SXFU";
export var settings = "Footer-module--settings--QqooC";
export var creditCardContainer = "Footer-module--creditCardContainer--WrN9n";
export var amexSize = "Footer-module--amexSize--mvQKT";
export var masterSize = "Footer-module--masterSize--Id9OQ";
export var visaSize = "Footer-module--visaSize--R+dKq";
export var copyrightContainer = "Footer-module--copyrightContainer--49DlH";
export var mobileFooterLinks = "Footer-module--mobileFooterLinks--IT04Z";
export var accordionTitle = "Footer-module--accordionTitle--O0ZYT";
export var iconContainer = "Footer-module--iconContainer--Sxwo-";
export var footerLinks = "Footer-module--footerLinks--GplZY";
export var footerLinkContainer = "Footer-module--footerLinkContainer--NM4Rs";